/* global _, $ */
import React, { Component } from 'react';
import moment from "moment";
import Swal from "sweetalert2";
import NotificationSystem from 'react-notification-system';

import PageHeader from '../Global/PageHeader';
import TextField from '../FormFields/TextField';
import RadioGroup from '../FormFields/RadioGroup';
import DateTime from 'react-datetime';
import { newVoucher } from '../../actions/vouchers/new_voucher';
import AreaService from '../Helpers/AreasMultiSelect';
import SelectField from '../FormFields/SelectField';

// styles
import './../../style/form.css';

const typeOptions = [
  {
    label: 'Valor',
    value: 'V'
  },
  {
    label: 'Percentual',
    value: 'P'
  }
];
const yesAndNo = [
  {
    label: 'Sim',
    value: 'true'
  },
  {
    label: 'Não',
    value: 'false'
  }
];
"Alocação"
"Customer Experience"
"Cortesia"
const sectors = [
  { value: "atendimento", text: "Atendimento" },
  { value: "cliente", text: "Cliente" },
  { value: "financeiro", text: "Financeiro" },
  { value: "marketing", text: "Marketing" },
  { value: "operacoes", text: "Operações" },
  { value: "qualidade", text: "Qualidade" },
  { value: "sinergia", text: "Sinergia" },
  { value: "B2B", text: "B2B" },
  { value: "RH", text: "RH" },
  { value: "expansao", text: "Expansão" },
  { value: "alocacao", text: "Alocação" },
  { value: "customerexperience", text: "Customer Experience" },
  { value: "cortesia", text: "Cortesia" },
  { value: "concierge_whatsapp", text: "Concierge Whatsapp" }
];

const replaceCampaign = value => value.replace(/[^A-Za-z0-9]/g, '');
const today = moment();

class NewVoucherForm extends Component {
  state = {
    campaign: '',
    code: '',
    startDate: '',
    endDate: '',
    dateRangeStart: '',
    dateRangeEnd: '',
    locations: '',
    services: '',
    userId: '',
    sector: '',
    description: '',
    aquisitionType: '',
    aquisitionValue: '',
    aquisitionMaxTimes: '',
    aquisitionNumberOfClients: '',
    aquisitionTimesByUser: '',
    aquisitionPublicVoucher: false,
    recurrenceType: '',
    recurrenceValue: '',
    recurrenceMaxTimes: '',
    recurrenceNumberOfClients: '',
    recurrenceTimesByUser: '',
    recurrencePublicVoucher: false,
  }

  isValidStartDate = date => date.isAfter(today) || date.isSame(today, 'date');

  isValidEndDate = date => {
    const { startDate } = this.state;

    const isAfterToday = date.isAfter(today) || date.isSame(today, 'date');

    if (startDate) {
      return isAfterToday && startDate && date.isAfter(startDate, 'date');
    }

    return isAfterToday;
  };

  selectedAreas = (locations) => {
    this.setState({ locations });
  };

  selectedServices = (services) => {
    this.setState({ services })
  };


  formatVoucherData(values) {
    const formatted = {
      code: values.code,
      campaign: values.campaign,
      userId: values.userId,
      sector: values.sector,
      description: values.description,
      end: values.endDate.toDate(),
      start: values.startDate.toDate(),
      dateRangeStart: values.dateRangeStart && values.dateRangeStart.toDate() || "",
      dateRangeEnd: values.dateRangeEnd && values.dateRangeEnd.toDate() || "",
      locations: values.locations,
      services: values.services,
      value: parseInt(values.aquisitionValue, 10) || parseInt(values.recurrenceValue, 10),
    }

    if (values.aquisitionType && values.aquisitionValue) {
      Object.assign(formatted, {
        aquisition: {
          type: values.aquisitionType,
          value: parseInt(values.aquisitionValue, 10),
          maxTimes: parseInt(values.aquisitionMaxTimes, 10),
          numberOfClients: parseInt(values.aquisitionNumberOfClients, 10),
          timesByUser: parseInt(values.aquisitionTimesByUser, 10),
          publicVoucher: values.aquisitionPublicVoucher === 'true',
          justNew: true,
        }
      });
    }

    if (values.recurrenceType && values.recurrenceValue) {
      Object.assign(formatted, {
        recurrence: {
          type: values.recurrenceType,
          value: parseInt(values.recurrenceValue, 10),
          maxTimes: parseInt(values.recurrenceMaxTimes, 10),
          numberOfClients: parseInt(values.recurrenceNumberOfClients, 10),
          timesByUser: parseInt(values.recurrenceTimesByUser, 10),
          publicVoucher: values.recurrencePublicVoucher === 'true',
          justNew: false,
        },
      });
    }

    return formatted;
  }

  notify(message, level) {
    const title = level === "success" ? "Sucesso" : "Erro";
    this.refs.notificationSystem.addNotification({
      title,
      message,
      level,
      position: "tc"
    });
  }

  onSubmit = (e) => {
    const {
      code,
      campaign,
      userId,
      sector,
      description,
      startDate,
      endDate,
      dateRangeStart,
      dateRangeEnd,
      locations,
      services,
      aquisitionType,
      aquisitionMaxTimes,
      aquisitionNumberOfClients,
      aquisitionPublicVoucher,
      aquisitionTimesByUser,
      aquisitionValue,
      recurrenceMaxTimes,
      recurrenceNumberOfClients,
      recurrencePublicVoucher,
      recurrenceTimesByUser,
      recurrenceType,
      recurrenceValue
    } = this.state;


    const data = {
      code,
      campaign,
      userId,
      description,
      startDate,
      endDate,
      dateRangeStart,
      dateRangeEnd,
      locations,
      sector,
      services,
      aquisitionType,
      aquisitionMaxTimes,
      aquisitionNumberOfClients,
      aquisitionPublicVoucher,
      aquisitionTimesByUser,
      aquisitionValue,
      recurrenceMaxTimes,
      recurrenceNumberOfClients,
      recurrencePublicVoucher,
      recurrenceTimesByUser,
      recurrenceType,
      recurrenceValue
    };

    e.preventDefault();

    const format = this.formatVoucherData(data);

    newVoucher(format)
      .then(() => {
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Voucher criado com sucesso!"
        });
        window.location.href = '/#/vouchers';
      }).catch(error => {
        console.error(error);
        const message = error?.response?.data?.error?.description;
        Swal.fire({
          icon: "error",
          title: "Opss..",
          text: message || "Erro ao criar voucher"
        });
      }).finally(() => {
      })
  }


  render() {
    const {
      code,
      campaign,
      userId,
      sector,
      description,
      startDate,
      endDate,
      dateRangeStart,
      dateRangeEnd,
      aquisitionType,
      aquisitionMaxTimes,
      aquisitionNumberOfClients,
      aquisitionPublicVoucher,
      aquisitionTimesByUser,
      aquisitionValue,
      recurrenceMaxTimes,
      recurrenceNumberOfClients,
      recurrencePublicVoucher,
      recurrenceTimesByUser,
      recurrenceType,
      services,
      locations,
      recurrenceValue
    } = this.state;

    return (
      <form onSubmit={this.onSubmit}
        className={`Form `}>
        <NotificationSystem ref="notificationSystem" />
        <PageHeader title="Adicionar Voucher" />

        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-4">
              <TextField
                name="code"
                label="Código *"
                inputData={{
                  value: code,
                  onChange: (code) => this.setState({ code })
                }}
                placeholder="Insira o código"
              />
            </div>

            <div className="col-sm-4">
              <TextField
                name="campaign"
                label="Campanha"
                inputData={{
                  value: campaign,
                  onChange: (campaign) => this.setState({ campaign })
                }}
                placeholder="Insira a campanha"
                replace={replaceCampaign}
                maxlength={100}
              />
            </div>

            <div className="col-sm-4">
              <TextField
                name="userId"
                label="Id do usuário"
                inputData={{
                  value: userId,
                  onChange: (userId) => this.setState({ userId })
                }}
                placeholder="ID do usuário"
                maxlength={20}
              />
            </div>
          </div>
          <div className="row">

            <div className="col-sm-4">
              <SelectField
                name="sector"
                label="Setor responsável pelo voucher: "
                listValues={sectors}
                inputData={{
                  value: sector,
                  onChange: (sector) => this.setState({ sector })
                }}
                placeholder="Setor responsável"
                maxlength={20}
              />
            </div>
          </div>
        </div>

        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-6">
              <TextField
                name="description"
                label="Descrição"
                inputData={{
                  value: description,
                  onChange: (description) => this.setState({ description })
                }}
                multiline
                margin="normal"
                placeholder="Descrição sobre o voucher"
                maxlength={200}
              />
            </div>
            <div className="col-sm-3">
              <div className="Form-labelWrapper">
                <div className="Form-label">
                  <span>Data de início *</span>
                </div>
              </div>
              <DateTime
                name="evaluationDate"
                viewMode="time"
                value={startDate}
                dateFormat="DD/MM/YYYY"
                isValidDate={this.isValidStartDate}
                onChange={(e) => this.setState({ startDate: e })}
                className="Form-input input-date"
              />
            </div>
            <div className="col-sm-3">
              <div className="Form-labelWrapper">
                <div className="Form-label">
                  <span>Data final *</span>
                </div>
              </div>
              <DateTime
                name="evaluationDate"
                viewMode="time"
                value={endDate}
                dateFormat="DD/MM/YYYY"
                isValidDate={this.isValidEndDate}
                onChange={(e) => this.setState({ endDate: e })}
                className="Form-input input-date"
              />
            </div>
          </div>
        </div>

        <div className="Form-formGroup">
          <div className="row">
            <div className="col-sm-3">
              <div className="Form-labelWrapper">
                <div className="Form-label">
                  <span>Horário permitido para uso - inicio</span>
                </div>
              </div>
              <DateTime
                name="evaluationDate"
                viewMode="time"
                value={dateRangeStart}
                dateFormat="DD/MM/YYYY"
                isValidDate={this.isValidStartDate}
                onChange={(e) => this.setState({ dateRangeStart: e })}
                className="Form-input input-date"
              />
            </div>
            <div className="col-sm-3">
              <div className="Form-labelWrapper">
                <div className="Form-label">
                  <span>Horário permitido para uso uso - final</span>
                </div>
              </div>
              <DateTime
                name="evaluationDate"
                viewMode="time"
                value={dateRangeEnd}
                dateFormat="DD/MM/YYYY"
                isValidDate={this.isValidEndDate}
                onChange={(e) => this.setState({ dateRangeEnd: e })}
                className="Form-input input-date"
              />
            </div>
          </div>
        </div>

        <div className="Form-formGroup">
          <h3>Aquisição:</h3>
          <div className="row">
            <div className="col-sm-4">
              <RadioGroup
                name="aquisitionType"
                label="Tipo *"
                list={typeOptions}
                inputData={{
                  value: aquisitionType,
                  onChange: (aquisitionType) => this.setState({ aquisitionType })
                }}
              />
            </div>
            <div className="col-sm-4">
              <RadioGroup
                name="aquisitionPublicVoucher"
                label="Mostrar no aplicativo *"
                list={yesAndNo}
                inputData={{
                  value: aquisitionPublicVoucher,
                  onChange: (aquisitionPublicVoucher) => this.setState({ aquisitionPublicVoucher })
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              {aquisitionType && aquisitionType === 'P' ?
                <TextField
                  name="value"
                  label="Valor *"
                  inputData={{
                    value: aquisitionValue,
                    onChange: (aquisitionValue) => this.setState({ aquisitionValue })
                  }}
                  placeholder="Insira o valor"
                  percentage={true}
                /> :
                <TextField
                  name="value"
                  label="Valor *"
                  inputData={{
                    value: aquisitionValue,
                    onChange: (aquisitionValue) => this.setState({ aquisitionValue })
                  }}
                  placeholder="Insira o valor"
                  number={true}
                />}
            </div>
            <div className="col-sm-3">
              <TextField
                name="aquisitionMaxTimes"
                label="Máximo de vezes de uso *"
                inputData={{
                  value: aquisitionMaxTimes,
                  onChange: (aquisitionMaxTimes) => this.setState({ aquisitionMaxTimes })
                }}
                placeholder="Insira 0 para ilimitado"
                number={true}
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="aquisitionTimesByUser"
                label="Vezes por usuário *"
                inputData={{
                  value: aquisitionTimesByUser,
                  onChange: (aquisitionTimesByUser) => this.setState({ aquisitionTimesByUser })
                }}
                placeholder="Insira o número de vezes de uso por usuário"
                number={true}
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="aquisitionNumberOfClients"
                label="Máximo de clientes no pedido *"
                inputData={{
                  value: aquisitionNumberOfClients,
                  onChange: (aquisitionNumberOfClients) => this.setState({ aquisitionNumberOfClients })
                }}
                placeholder="Insira 0 para indiferente"
                number={true}
              />
            </div>
          </div>
        </div>

        <div className="Form-formGroup">
          <h3>Recorrência:</h3>
          <div className="row">
            <div className="col-sm-4">
              <RadioGroup
                name="recurrenceType"
                label="Tipo *"
                list={typeOptions}
                inputData={{
                  value: recurrenceType,
                  onChange: (recurrenceType) => this.setState({ recurrenceType })
                }}
              />
            </div>
            <div className="col-sm-4">
              <RadioGroup
                name="recurrencePublicVoucher"
                label="Mostrar no aplicativo *"
                list={yesAndNo}
                inputData={{
                  value: recurrencePublicVoucher,
                  onChange: (recurrencePublicVoucher) => this.setState({ recurrencePublicVoucher })
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-sm-3">
              {recurrenceType && recurrenceType === 'P' ?
                <TextField
                  name="value"
                  label="Valor *"
                  inputData={{
                    value: recurrenceValue,
                    onChange: (recurrenceValue) => this.setState({ recurrenceValue })
                  }}
                  placeholder="Insira o valor"
                  percentage={true}
                /> :
                <TextField
                  name="value"
                  label="Valor *"
                  inputData={{
                    value: recurrenceValue,
                    onChange: (recurrenceValue) => this.setState({ recurrenceValue })
                  }}
                  placeholder="Insira o valor"
                  number={true}
                />}
            </div>
            <div className="col-sm-3">
              <TextField
                name="recurrenceMaxTimes"
                label="Máximo de vezes de uso *"
                inputData={{
                  value: recurrenceMaxTimes,
                  onChange: (recurrenceMaxTimes) => this.setState({ recurrenceMaxTimes })
                }}
                placeholder="Insira 0 para ilimitado"
                number={true}
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="recurrenceTimesByUser"
                label="Vezes por usuário *"
                inputData={{
                  value: recurrenceTimesByUser,
                  onChange: (recurrenceTimesByUser) => this.setState({ recurrenceTimesByUser })
                }}
                placeholder="Insira o número de vezes de uso por usuário"
                number={true}
              />
            </div>
            <div className="col-sm-3">
              <TextField
                name="recurrenceNumberOfClients"
                label="Máximo de clientes no pedido *"
                inputData={{
                  value: recurrenceNumberOfClients,
                  onChange: (recurrenceNumberOfClients) => this.setState({ recurrenceNumberOfClients })
                }}
                placeholder="Insira 0 para indiferente"
                number={true}
              />
            </div>
          </div>
        </div>

        <AreaService
          defaultAreas={locations}
          defaultServices={services}
          setServices={this.selectedServices}
          setAreas={this.selectedAreas} />

        <div className="Form-submitWrapper">
          <div className="row">
            <div className="col-sm-12">
              <button className="Form-submit"
                type="submit">
                <span>CRIAR NOVO VOUCHER</span>
                <span className="ss-icon ss-book"></span>
              </button>
            </div>
          </div>
        </div>
      </form >
    );
  }
}

export default NewVoucherForm;
