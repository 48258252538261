import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import bonus from "../../../../helper/bonus";
import moment from 'moment';
import NotificationSystem from "react-notification-system";
import getScheduleById from '../../../../services/schedules';

const reasons = bonus.REASONS;

class ArtistBonus extends Component {
  state = {
    transferValue: 0,
    reason: 2,
    scheduleId: '',
    scheduleDate: '',
    description: '',
    transferId: '',
    user: JSON.parse(localStorage.user).name,
  }

  componentDidUpdate(prevProps) {
    if (prevProps.bonus !== this.props.bonus) {
      const { bonus } = this.props;
      if (bonus) {
        this.setState({
          transferValue: (bonus.value / 100) || 0,
          reason: bonus.reason || 2,
          scheduleId: bonus?.schedule._id || '',
          scheduleDate: bonus?.schedule.date || '',
          description: bonus.description || '',
          user: bonus.responsible.name || '',
          transferId: bonus._id || '',
        });
      }
    }
  }

  validateField = (fieldName, value) => {
    this.setState({
      [`${fieldName}Error`]: value ? null : 'Preenchimento Obrigatório',
    });
  };

  getIdSchedulesBonus = ({ scheduleId }) => {
    return getScheduleById(scheduleId);
  }

  handleBlurSchedules = () => {
    const scheduleId = this.state.scheduleId;

    if (scheduleId) {
      this.getIdSchedulesBonus({ scheduleId })
        .then(({ data: schedule }) => {
          this.setState({ scheduleDate: schedule.date });
        })
        .catch((error) => {
          alert('Erro ao consultar agendamento!');
        });
    }
  }

  formatDateForInput = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DDTHH:mm');
    return moment(formattedDate, 'YYYY-MM-DDTHH:mm', true).isValid()
      ? formattedDate
      : '';
  };

  notify(message, level) {
    const title = level === "success" ? "Sucesso" : "Erro";
    this.refs.notificationSystem.addNotification({
      title,
      message,
      level,
      position: "tc"
    });
  }

  payBonus = () => {
    const {
      transferValue,
      reason,
      scheduleId,
      scheduleDate,
      description,
      transferId,
    } = this.state;

    this.validateField('transferValue', transferValue);
    this.validateField('scheduleId', scheduleId);
    this.validateField('scheduleDate', scheduleDate);
    this.validateField('description', description);

    if (
      transferValue &&
      scheduleId &&
      scheduleDate &&
      description
    ) {
      if (transferId) {
        this.props.editBonus({
          transferId: transferId,
          value: transferValue,
          reason: reason,
          description: description,
          scheduleId: scheduleId,
          scheduleDate: moment(scheduleDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }).then(() => {
          this.props.closeArtistBonus();
          window.location.reload();
        }).catch(err => {
          this.notify(err.response.data.error, "error");
        });
      } else {
        this.props.payBonus({
          value: transferValue,
          reason: reason,
          description: description,
          scheduleId: scheduleId,
          scheduleDate: moment(scheduleDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        }).then(() => {
          this.props.closeArtistBonus();
          window.location.reload();
        }).catch(err => {
          this.notify(err.response.data.error, "error");
        });
      }
    }
  };

  render() {
    return (
      <div>
        <NotificationSystem ref="notificationSystem" />
        <Modal show={this.props.open} onHide={this.props.closeArtistBonus}>
          <Modal.Header closeButton>
            <Modal.Title>Pagar profissional</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group">
              <div className="row">
                <div className="col-sm-6">
                  <label className="form-label">Profissional*</label>
                  <input
                    type="text"
                    className="Form-input"
                    value={this.props.artist.name} disabled />
                </div>
                <div className="col-sm-6">
                  <label className="form-label">Responsável*</label>
                  <input
                    type="text"
                    className="Form-input"
                    value={this.state.user}
                    disabled
                  />
                </div>
                <div className="col-sm-6 pt-1">
                  <label className="form-label">Objetivo</label>
                  <br />
                  <select
                    className="Form-input custom-select"
                    onChange={e => this.setState({ reason: e.target.value })}
                    value={this.state.reason}
                  >
                    {reasons.map(r => (
                      <option value={r.id} key={r.id}>{r.description}</option>
                    ))}
                  </select>
                </div>
                <div className="col-sm-6 pt-1">
                  <label className="form-label">Valor* (Limite de 2000)</label>
                  <input
                    type="number"
                    max={2000}
                    min={0}
                    className={`Form-input ${this.state.transferValueError ? 'is-invalid' : ''}`}
                    value={this.state.transferValue}
                    onChange={(e) => {
                      const newValue = e.target.value > 2000 ? 2000 : (e.target.value < 0 ? 0 : e.target.value);
                      this.setState({ transferValue: newValue }, () => {
                        this.validateField('transferValue', newValue);
                      });
                    }}
                  />
                  <div className='invalid-feedback'>{this.state.transferValueError}</div>
                </div>
                <div className="col-sm-6 pt-1">
                  <label className="form-label">ID pedido*</label>
                  <input
                    type="text"
                    className={`Form-input ${this.state.scheduleIdError ? 'is-invalid' : ''}`}
                    value={this.state.scheduleId}
                    onChange={(e) => {
                      const newValue = e.target.value.trim();
                      this.setState({ scheduleId: newValue }, () => {
                        this.validateField('scheduleId', newValue);
                      });
                    }}
                    onBlur={this.handleBlurSchedules}
                  />

                  <div className='invalid-feedback'>{this.state.scheduleIdError}</div>
                </div>
                <div className="col-sm-6 pt-1">
                  <label className="form-label">Data pedido*</label>
                  <input
                    type="datetime-local"
                    className={`Form-input ${this.state.scheduleDateError ? 'is-invalid' : ''}`}
                    value={this.formatDateForInput(this.state.scheduleDate)}
                    onChange={(e) => {
                      const newValue = e.target.value;
                      this.setState({ scheduleDate: newValue }, () => {
                        this.validateField('scheduleDate', newValue);
                      });
                    }}
                  />
                  <div className='invalid-feedback'>{this.state.scheduleDateError}</div>
                </div>
                <div className="col-sm-12 pt-1">
                  <label className="form-label">Mensagem* (máximo de 72 caractérers)</label>
                  <textarea
                    maxLength={72}
                    className={`Form-input ${this.state.descriptionError ? 'is-invalid' : ''}`}
                    value={this.state.description}
                    onChange={(e) => {
                      const newValue = e.target.value.trimLeft().replace(/\s\s+/g, ' ');
                      this.setState({ description: newValue }, () => {
                        this.validateField('description', newValue);
                      });
                    }}
                  >
                  </textarea>
                  <div className='invalid-feedback'>{this.state.descriptionError}</div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" className="btn btn-warning" onClick={this.props.closeArtistBonus}>
              Cancelar
            </Button>
            <Button variant="primary" className="btn btn-success" onClick={this.payBonus}>
              Enviar
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default ArtistBonus;