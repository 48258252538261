/* eslint-disable no-unused-vars */
/* global _ */
/* global BACKEND_URL */
/* global axios */

import React, { Component, PropTypes } from "react";
import { connect } from "react-redux";
import moment from "moment-timezone";
import { Button, Modal } from "react-bootstrap";
import NotificationSystem from "react-notification-system";
import PageHeader from "./../../components/Global/PageHeader";
import formatter from "./../../helper/formatters";
import SchedulesGriddle from "./../../components/Schedules/SchedulesGriddle";
import ReadOnlyField from "./../../components/UserInfo/ReadOnlyField/ReadOnlyField";
import RemoveableField from "./../../components/UserInfo/RemoveableField/RemoveableField";
import EditableField from "./../../components/EditableField/EditableField";
import Loading from "./../../components/Global/Loading/Loading";
import handleRequest from "./../../helper/requests";
import Griddle from "griddle-react";
import VouchersMetadata from "./../Vouchers/Vouchers_metadata";
import Comments from "../Comments/Comments";
import ScheduleModal from "./ScheduleModal";
import Swal from "sweetalert2";

// actions
import {
  fetchUser,
  getArtistsByID,
  removeExcludedPartnerById,
} from "./../../actions/user_info/fetch_user";
import { fetchVouchersUsingUserId } from "./../../actions/vouchers/fetch_vouchers";
import { fetch_userSchedules } from "./../../actions/user_info/fetch_userSchedules";
import { fetchUserCreditsHistory } from "./../../actions/user_info/fetch_userCreditsHistory";
import { updateUser } from "./../../actions/user_info/update_user";
import { updateSinguCredits } from "./../../actions/user_info/update_singuCredits";
import { removeCard } from "./../../actions/user_info/remove_card";
import { removeAddress } from "./../../actions/user_info/remove_address";
import { addAddress } from "../../actions/user_info/add_address.js";
import { resetUserPassword } from "./../../actions/user_info/request_user_password_reset";
import { fetchAreas } from "./../../actions/areas/fetch_areas";
import { setSchedules } from "./../../actions/schedules/set_schedules";
import { updateScheduleAddress } from "./../../actions/schedules/update_schedule_address";
import { updateSchedulePartner } from "./../../actions/schedules/update_schedule_partner";
import { updateScheduleDate } from "./../../actions/schedules/update_schedule_date";
import { updateScheduleService } from "./../../actions/schedules/update_schedule_service";
import { updateScheduleVoucher } from "./../../actions/schedules/update_schedule_voucher";
import { cancelSchedule } from "./../../actions/schedules/cancel_schedule";
import { removeSchedulePartner } from "./../../actions/schedules/remove_schedule_partner";
import { rechargeSchedule } from "./../../actions/schedules/recharge_schedule.js";

// styles
import "./UserInfo.css";
import { blockUser } from "../../actions/users/block_user";
import { unBlockUser } from "../../actions/users/unblock_user";
import { AddressModal } from "./AddressModal.jsx";
import { forcePhoneValidation } from "../../actions/users/force-phone-validation.js";
//import { addAddress } from "../../services/users.js";

const FacebookField = (props) => {
  return (
    <div className="col-sm-3 col-xs-12">
      <div>Facebook</div>
      <div>
        <a href={`//facebook.com/${props.id}`}>{props.id}</a>
      </div>
    </div>
  );
};

const insertedCreditsReasons = [
  "M-PERMUTA",
  "M-GRAVAÇÃO ",
  "M-EVENTO ",
  "M-ERRO_PACOTE ",
  "M-ERRO_ASSINATURA ",
  "M-ERRO_CUPOM",
  "M-ERRO_MGM",
  "A-CUSTOMER_EXPERIENCE",
  "A-ATRASO",
  "Q-QUALIDADE",
];

class UserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savingCredits: false,
      creditsHistoryTotalItems: 0,
      currentCreditsHistoryPage: 1,
      user: null,
      userArrived: false,
      dataRequested: false,
      error: false,
      vouchersArrived: false,
      vouchers: [],
      schedules: [],
      numberOfPages: 0,
      modalOpened: false,
      singuCreditsError: "",
      singuDescriptionError: "",
      reasonError: "",
      currentPage: 1,
      creditsHistory: null,
      excludedPartners: null,
      professionalPreferences: null,
      modalSchedule: false,
      modalAddress: false,
      handleBlockUserLoading: false,
      handleForcePhoneValidationLoading: false,
    };
  }

  componentWillMount() {
    const { userId } = this.props.params;
    const success = (action) => {
      if (!action.error) {
        this.setState({ userArrived: true });
      }
    };

    const error = (err) => {
      console.error(err);
      this.setState({ error: true, vouchersArrived: true });
    };

    this.getUser(userId).then(success).catch(error);

    this.getVouchers(userId)
      .then((response) => {
        this.setState({ 
          vouchersArrived: true, 
          vouchers: response.data.vouchers
        });
      })
      .catch(error);

    this.getAllSchedules(1, {});
    this.getCreditsHistory(userId);
  }

  componentWillReceiveProps(newProps) {
    const { areas, vouchers, user } = newProps;
    const { dataRequested } = this.state;

    if (user) {
      this.mapUser(user);
      user.professionalPreferences &&
        this.getArtists(user.professionalPreferences, (err, response) => {
          if (err) return;

          if (response.data) {
            this.setState({ professionalPreferences: response.data });
          }
        });
      user.excludedPartners &&
        this.getArtists(user.excludedPartners, (err, response) => {
          if (err) return;

          if (response.data) {
            this.setState({ excludedPartners: response.data });
          }
        });
    }

    if (areas) {
      this.setState({ areas });
    }

    if (vouchers) {

      if (!dataRequested) {
        this.setState({ dataRequested: true }, this.loadData);
      }
    }
  }

  async loadData() {
    await this.getAllAreas();
  }

  setLoading = (loading) => this.setState({ loading });

  getAllSchedules = (
    page,
    {
      orderBy,
      orderType,
      filter,
      minDate,
      maxDate,
      status,
      area,
      shortCode,
      onlyPreference,
    }
  ) => {
    this.setLoading(true);

    const { userId } = this.props.params;

    if (userId) {
      return this.props
        .fetch_userSchedules(
          userId,
          page,
          orderBy,
          orderType,
          filter,
          minDate,
          maxDate,
          status,
          area,
          shortCode,
          onlyPreference
        )
        .then((res) => {
          this.setState({
            loading: false,
            currentPage: page,
            userSchedules: res.payload.data.schedules,
            numberOfPages: res.payload.data.totalItems,
          });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ loading: false });
        });
    } else {
      this.setState({ loading: false });
    }
  };

  getArtists(_ids, callback) {
    //_ids = '58f66b45d4281c747d3ce7f6,5e39f660d4bd455e54cb8d40,5fb07bd601b6a26e94a620fe';
    getArtistsByID(_ids)
      .then((response) => callback(null, response))
      .catch((err) => callback(err, null));
  }

  getUser(userId) {
    return this.props.fetchUser(userId);
  }

  getVouchers(userId) {
    return fetchVouchersUsingUserId(userId);
  }

  getCreditsHistory() {
    const { userId } = this.props.params;
    this.setState({ creditsHistory: null });
    return this.props
      .fetchUserCreditsHistory(userId, this.state.currentCreditsHistoryPage)
      .then((data) => {
        this.setState({
          creditsHistory: data.payload.data.history,
          creditsHistoryTotalItems: data.payload.data.totalItems,
        });
      })
      .catch(() => {
        this.setState({ creditsHistory: [] });
      });
  }

  renderRejectedPartners() {
    const { excludedPartners } = this.state;

    return (
      <div>
        {excludedPartners && excludedPartners.length > 0 ? (
          excludedPartners.map((partner, i) => {
            const info = [
              { _id: partner._id },
              { Nome: partner.name },
              { Email: partner.email },
            ];
            const removeAction = () => {
              return removeExcludedPartnerById(this.state.user.id, partner._id);
            };
            return (
              <RemoveableField
                text={partner.name}
                fullInfo={info}
                key={i}
                removeAction={() => removeAction()}
                notify={(msg, lvl) => {
                  if (lvl === "success") {
                    this.setState({
                      excludedPartners: excludedPartners.filter(
                        (v) => v._id !== partner._id
                      ),
                    });
                  }
                  this.notify(msg, lvl);
                }}
              />
            );
          })
        ) : (
          <p>Essa cliente não possui profissionais rejeitadas</p>
        )}
      </div>
    );
  }

  renderPreferencePartners() {
    const { professionalPreferences } = this.state;

    return (
      <div>
        {professionalPreferences && professionalPreferences.length > 0 ? (
          professionalPreferences.map((partner, index) => {
            return (
              <span key={`${partner._id + index}`}>
                <a href={`/#/artist/${partner._id}`}>{partner.name}</a>{" "}
              </span>
            );
          })
        ) : (
          <p>Essa cliente não possui preferencia por profissional</p>
        )}
      </div>
    );
  }

  renderVouchers() {
    const { vouchers, vouchersArrived } = this.state;

    if (!vouchersArrived) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    const columns = [
      "code",
      "campaign",
      "start",
      "end",
      "usedTimes",
      "value",
      "type"
    ];

    return (
      <div>
        <div className="row">
          <Griddle
            results={vouchers}
            columnMetadata={VouchersMetadata}
            columns={columns}
            showFilter={false}
            showSettings={false}
            resultsPerPage={100}
            useFixedLayout={false}
            useGriddleStyles={false}
            tableClassName={"GriddleTable table"}
            settingsToggleClassName={"btn btn-default"}
          />
        </div>
      </div>
    );
  }

  getAllAreas() {
    return this.props.fetchAreas();
  }

  notify(message, level) {
    const { notificationSystem } = this.refs;
    const title = level === "success" ? "Sucesso" : "Erro";

    notificationSystem.addNotification({
      title,
      message,
      level,
      position: "tc",
    });
  }

  mapUser(user) {
    const dateFormat = "DD/MM/YYYY HH:mm";
    const mapAddresses = (address) => {
      return {
        text: `${address.place} - ${address.city}, ${address.streetNumber}${address.complement ? ", " + address.complement : ""
          }  `,
        id: address._id,
        lat: address.lat,
        lng: address.lng,
        areaId: address.areaId,
        fullInfo: [
          { Cidade: `${address.city}/${address.uf}` },
          { Bairro: address.neighbor },
          { Rua: `${address.place} - ${address.streetNumber}` },
          { Complemento: address.complement },
          { CEP: address.zipCode },
        ],
      };
    };
    const mapCreditCards = (card) => {
      const displayNumber = `${card?.brand} - ${card?.first6}** **** ${card?.last4}`;
      return {
        provider: card?.provider || "moip",
        text: displayNumber,
        id: card.id,
        fullInfo: [
          { Bandeira: card?.brand },
          { Número: displayNumber },
          { Titular: card?.holder?.fullname },
        ],
      };
    };
    const mappedUser = {
      id: user._id,
      name: user.name,
      active: user.active ? "Sim" : "Não",
      voucherCode: user.voucherCode || "N/A",
      numberOfSchedules: user.numberOfSchedules || 0,
      lastLogin:
        (user.lastLogin && moment(user.lastLogin).format(dateFormat)) || "",
      createdAt:
        (user.createdDate && moment(user.createdDate).format(dateFormat)) || "",
      facebook: user.provider === "facebook" ? user.providerId : null,
      emailPhoneConfirmed: user.emailPhoneConfirmed ? "Sim" : "Não",
      phoneConfirmed: user.phone?.validatedAt ? "Sim" : "Não",
      phone: formatter.formatPhone(user.phone),
      birthdate:
        (user.birthdate && moment(user.birthdate).format("DD/MM/YYYY")) || "",
      email: user.email,
      gender: formatter.formatGender(user.gender),
      addresses: _.map(user.address, mapAddresses),
      creditCards: _.map(user.creditCards, mapCreditCards).filter(
        (card) => card.provider === "pagbank"
      ),
      creditsHistory: user.creditsHistory || [],
      singuCredits: user.singuCredits || 0,
      hasSignature: user.hasSignature ? "Sim" : "Não",
      vip: user.vip ? "Sim" : "Não",
      professionalPreferences: user.professionalPreferences,
      excludedPartners: user.excludedPartners,
      isBlocked: !user.active && user.blocked ? true : false,
    };

    this.setState({ user: mappedUser });
  }

  renderSchedules() {
    const { vouchers, areas, userSchedules } = this.state;
    const data = {
      vouchers,
      areas,

      dataConfirmationAction: this.props.updateUser,
      updateAddressAction: this.props.updateScheduleAddress,
      updatePartnerAction: this.props.updateSchedulePartner,
      removePartnerAction: this.props.removeSchedulePartner,
      updateDateAction: this.props.updateScheduleDate,
      updateServiceAction: this.props.updateScheduleService,
      updateVoucherAction: this.props.updateScheduleVoucher,
      cancelScheduleAction: this.props.cancelSchedule,
      rechargeScheduleAction: this.props.rechargeSchedule,
    };

    return (
      <SchedulesGriddle
        {...data}
        schedules={userSchedules}
        currentPage={this.state.currentPage}
        numberOfPages={Math.round(this.state.numberOfPages / 10)}
        getSchedules={this.getAllSchedules}
        loading={this.state.loading}
      />
    );
  }

  onDataConfirmationClick() {
    const { id } = this.state.user;
    const actionCreator = () =>
      this.props.updateUser(id, { emailPhoneConfirmed: false });
    const successMsg = "Requisição de confimação de dados enviada com sucesso";
    const notifyFn = (msg, lvl) => this.notify(msg, lvl);

    return handleRequest(actionCreator, notifyFn, successMsg);
  }

  onPasswordResetClick() {
    const { email } = this.state.user;
    const actionCreator = () => this.props.resetUserPassword(email);
    const successMsg = "Requisição de senha enviada com sucesso";
    const notifyFn = (msg, lvl) => this.notify(msg, lvl);

    return handleRequest(actionCreator, notifyFn, successMsg);
  }

  displayAddresses() {
    const { user } = this.state;
    if (user) {
      if (user.addresses.length <= 0) {
        return (
          <div className="UserInfo-subSectionInfo">
            Nenhum endereço cadastrado
          </div>
        );
      }
      return _.map(user.addresses, (address, i) => {
        const removeAction = () =>
          this.props.removeAddress(user.id, address.id, i);
        return (
          <RemoveableField
            text={address.text}
            fullInfo={address.fullInfo}
            key={i}
            removeAction={removeAction}
            notify={(msg, lvl) => this.notify(msg, lvl)}
          />
        );
      });
    }
  }

  displayCreditCards() {
    const { user } = this.state;
    if (user) {
      if (user.creditCards.length <= 0) {
        return (
          <div className="UserInfo-subSectionInfo">
            Nenhum cartão de crédito cadastrado
          </div>
        );
      }
      return _.map(user.creditCards, (card, i) => {
        const removeAction = () => this.props.removeCard(user.id, card.id, i);
        return (
          <RemoveableField
            text={card.text}
            fullInfo={card.fullInfo}
            key={i}
            removeAction={removeAction}
            notify={(msg, lvl) => this.notify(msg, lvl)}
          />
        );
      });
    }
  }

  getSinguCreditsPage = (nextPage) => {
    const currentCreditsHistoryPage = nextPage + 1;
    this.setState(
      { currentCreditsHistoryPage },
      this.getCreditsHistory.bind(this, this.state.user.id)
    );
  };

  displaySinguCredits() {
    const { creditsHistory } = this.state;
    if (creditsHistory === null) {
      return (
        <div>
          <Loading />
        </div>
      );
    }

    if (creditsHistory.length >= 0) {
      return (
        <div className="col-xs-12">
          <Griddle
            useExternal
            externalMaxPage={Math.ceil(
              this.state.creditsHistoryTotalItems / 10
            )}
            externalSetPage={this.getSinguCreditsPage}
            externalCurrentPage={this.state.currentCreditsHistoryPage - 1}
            results={creditsHistory
              .map((item) => {
                if (Object.keys(item).length > 0) {
                  return {
                    createdAt: moment(item.createdAt).format("DD/MM/Y"),
                    value: item.credits,
                    decrease: item.decrease,
                    reason: item.reason || "",
                    description: item.description,
                    scheduleId: item.scheduleId,
                  };
                }
              })
              .filter((notUndefined) => notUndefined)}
            columns={[
              "createdAt",
              "value",
              "reason",
              "description",
              "scheduleId",
            ]}
            columnMetadata={[
              {
                columnName: "createdAt",
                displayName: "Data da Criação",
              },
              {
                columnName: "value",
                displayName: "Valor",
                customComponent: (row) => {
                  if (row.rowData.decrease) {
                    return (
                      <div style={{ color: "red" }}>
                        {" "}
                        R$ - {row.data.toFixed("2").replace(".", ",")}
                      </div>
                    );
                  }
                  return (
                    <div style={{ color: "green" }}>
                      {" "}
                      R$ + {row.data.toFixed("2").replace(".", ",")}
                    </div>
                  );
                },
              },
              {
                columnName: "reason",
                displayName: "Motivo",
              },
              {
                columnName: "description",
                displayName: "Descrição",
              },
              {
                columnName: "scheduleId",
                displayName: "Agendamento",
              },
            ]}
            showFilter={false}
            showSettings={false}
            resultsPerPage={10}
            useFixedLayout={false}
            useGriddleStyles={false}
            tableClassName={"GriddleTable table"}
            settingsToggleClassName={"btn btn-default"}
          />
        </div>
      );
    } else {
      return <div>Não existem histórico de créditos a serem exibidos</div>;
    }
  }

  onAddCreditsClick() {
    this.setState({
      modalOpened: true,
    });
  }

  closeModal() {
    this.setState({
      modalOpened: false,
    });
  }

  validateCredits = () => {
    const { singuCreditsValue } = this.state;
    this.setState({
      singuCreditsError: singuCreditsValue ? null : "Somente valores negativos",
    });
  };

  validateDescription = () => {
    const { description } = this.state;
    this.setState({
      singuDescriptionError: description ? null : "Preenchimento Obrigatório",
    });
  };

  validateReason = () => {
    const { reason } = this.state;
    this.setState({ reasonError: reason ? null : "Preenchimento Obrigatório" });
  };

  saveSinguCredits = () => {
    const { singuCreditsValue, description, reason, scheduleId, user } =
      this.state;

    if (singuCreditsValue && description && reason) {
      this.setState({ savingCredits: true });
      updateSinguCredits(
        user.id,
        singuCreditsValue,
        description,
        scheduleId,
        reason
      )
        .then(() => {
          this.getCreditsHistory();
          this.setState({ savingCredits: false, modalOpened: false });
        })
        .catch((error) => {
          alert(error);
        });
    } else {
      this.validateDescription();
      this.validateCredits();
      this.validateReason();
    }
  };

  handleForcePhoneValidation = async () => {
    this.setState({ handleForcePhoneValidation: true });
    const response = await forcePhoneValidation(this.state.user.id);

    if (response.status === 200) {
      window.location.reload();
      this.setState({ handleForcePhoneValidation: false });
    }
    this.setState({ handleForcePhoneValidation: false });
  };

  handleBlockUser = async () => {
    this.setState({ handleBlockUserLoading: true });
    const response = await blockUser(this.state.user.id);
    if (response.status === 200) {
      window.location.reload();
      this.setState({ handleBlockUserLoading: false });
    } else {
      this.setState({ handleBlockUserLoading: false });
    }
    this.setState({ handleBlockUserLoading: false });
  };

  handleUnBlockUser = async () => {
    this.setState({ handleBlockUserLoading: true });
    const response = await unBlockUser(this.state.user.id);
    if (response.status === 200) {
      window.location.reload();
      this.setState({ handleBlockUserLoading: false });
    } else {
      this.setState({ handleBlockUserLoading: false });
    }
    this.setState({ handleBlockUserLoading: false });
  };

  handleOnSaveAddress = async (address) => {
    try {
      const user = this.state.user;
      await this.props.addAddress(user.id, address);
      this.setState({ modalAddress: false });
      this.notify("Endereço adicionado!", "success");
    } catch (error) {
      Swal.fire(
        "Falha",
        "Ocorreu um erro ao adicionar o seu endereço, verifique os campo ou a disponibilidade da região."
      );
    }
  };

  render() {
    const { user, userArrived } = this.state;

    if (!userArrived) {
      return <Loading />;
    }

    const notAvailable = "Não informado";
    const onUpdate = (data) => this.props.updateUser(user.id, data);

    return (
      <div className="UserInfo">
        <NotificationSystem ref="notificationSystem" />
        <PageHeader title={this.state.user.name} />

        <div className="UserInfo-section">
          <div className="UserInfo-title">Informações</div>

          <div className="UserInfo-subSection row">
            <div className="col-sm-3 col-xs-12">
              <EditableField
                initialValue={user.name}
                fieldTitle="Nome"
                fieldName="name"
                defaultValue={notAvailable}
                updateCallback={onUpdate}
              />
            </div>

            <div className="col-sm-3 col-xs-12">
              <ReadOnlyField title="ID" info={user.id} />
            </div>

            <div className="col-sm-3 col-xs-12">
              <ReadOnlyField title="Ativo" info={user.active} />
            </div>

            <div className="col-sm-3 col-xs-12">
              <ReadOnlyField title="Voucher MGM" info={user.voucherCode} />
            </div>
          </div>

          <div className="UserInfo-subSection">
            <div className="UserInfo-subSection row">
              <div className="col-sm-3 col-xs-12">
                <EditableField
                  initialValue={user.birthdate}
                  fieldTitle="Data nascimento"
                  fieldName="birthdate"
                  defaultValue={notAvailable}
                  updateCallback={onUpdate}
                  type="date"
                  dateFormat={"DD-MM-YYYY"}
                />
              </div>
              <div className="col-sm-3 col-xs-12">
                <EditableField
                  initialValue={user.gender}
                  fieldTitle="Sexo"
                  fieldName="gender"
                  defaultValue={notAvailable}
                  updateCallback={onUpdate}
                />
              </div>
            </div>

            <div className="UserInfo-subSection row">
              <div className="col-sm-3 col-xs-12">
                <EditableField
                  initialValue={user.email}
                  fieldTitle="Email"
                  fieldName="email"
                  defaultValue={notAvailable}
                  updateCallback={onUpdate}
                />
              </div>

              <div className="col-sm-3 col-xs-12">
                <EditableField
                  initialValue={user.phone}
                  fieldTitle="Telefone"
                  fieldName="phone"
                  defaultValue={notAvailable}
                  updateCallback={onUpdate}
                  type="phone"
                />
              </div>
              {user.facebook ? <FacebookField id={user.facebook} /> : null}
              <div className="col-sm-3 col-xs-12">
                <ReadOnlyField
                  title="Email confirmado"
                  info={user.emailPhoneConfirmed}
                />
              </div>
              <div className="col-sm-3 col-xs-12">
                <ReadOnlyField
                  title="Telefone confirmado"
                  info={user.phoneConfirmed}
                />
              </div>
            </div>

            <div className="UserInfo-subSection row">
              <div className="col-sm-3 col-xs-12">
                <ReadOnlyField title="Cadastrado em" info={user.createdAt} />
              </div>

              <div className="col-sm-3 col-xs-12">
                <ReadOnlyField title="Último Login" info={user.lastLogin} />
              </div>

              <div className="col-sm-3 col-xs-12">
                <ReadOnlyField
                  title="Número de pedidos"
                  info={user.numberOfSchedules}
                />
              </div>

              <div className="col-sm-3 col-xs-12">
                <ReadOnlyField title="VIP" info={user.vip} />
              </div>

              <div className="col-sm-3 col-xs-12">
                <ReadOnlyField title="Assinatura" info={user.hasSignature} />
              </div>
            </div>
          </div>
        </div>

        <div className="UserInfo-section">
          <div className="row">
            <div className="col-lg-12">
              <div className="UserInfo-title">Endereços</div>
              <div className="UserInfo-removeablesWrapper">
                {this.displayAddresses()}
              </div>
            </div>
          </div>
        </div>

        <div className="UserInfo-section">
          <div className="row">
            <div className="col-lg-12">
              <div className="UserInfo-title">Cartões de crédito</div>
              <div className="UserInfo-removeablesWrapper">
                {this.displayCreditCards()}
              </div>
            </div>
          </div>
        </div>

        <div className="UserInfo-section">
          <div className="UserInfo-title">Ações</div>
          <div className="row">
            <div className="col-sm-12">
              <Button
                className="UserInfo-actionBtn"
                bsStyle="info"
                onClick={() => this.onPasswordResetClick()}
              >
                Mandar email de resetar senha
              </Button>
              <Button
                className="UserInfo-actionBtn"
                bsStyle="info"
                onClick={() => this.onDataConfirmationClick()}
              >
                Pedir confirmação dos dados
              </Button>
              {user.isBlocked ? (
                <Button
                  className="UserInfo-actionBtn"
                  bsStyle="info"
                  onClick={this.handleUnBlockUser}
                >
                  {this.state.handleBlockUserLoading ? (
                    <span className="spinner glyphicon glyphicon-refresh" />
                  ) : (
                    "Desbloquear"
                  )}
                </Button>
              ) : (
                <Button
                  className="UserInfo-actionBtn"
                  bsStyle="info"
                  onClick={this.handleBlockUser}
                >
                  {this.state.handleBlockUserLoading ? (
                    <span className="spinner glyphicon glyphicon-refresh" />
                  ) : (
                    "Bloquear"
                  )}
                </Button>
              )}
              <Button
                className="UserInfo-actionBtn"
                bsStyle="info"
                onClick={() => this.setState({ modalAddress: true })}
              >
                Adicionar Endereço
              </Button>
              {user.phoneConfirmed === "Não" && (
                <Button
                  className="UserInfo-actionBtn"
                  bsStyle="info"
                  onClick={this.handleForcePhoneValidation}
                >
                  {this.state.handleForcePhoneValidationLoading ? (
                    <span className="spinner glyphicon glyphicon-refresh" />
                  ) : (
                    "Validar telefone"
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
        <AddressModal
          show={this.state.modalAddress}
          close={() => this.setState({ modalAddress: false })}
          onSave={this.handleOnSaveAddress}
        />
        {this.state.modalSchedule && (
          <div className="row">
            <ScheduleModal
              modalSchedule={this.state.modalSchedule}
              hideModal={() => this.setState({ modalSchedule: false })}
              user={this.state.user}
              professionalPreferences={this.state.professionalPreferences}
            />
          </div>
        )}

        <div className="UserInfo-section">
          <div className="row">
            <div className="col-lg-12">
              <div className="UserInfo-title">
                Profissionais rejeitadas de {this.state.user.name}
              </div>
              <div className="UserInfo-removeablesWrapper">
                {this.renderRejectedPartners()}
              </div>
            </div>
          </div>
        </div>

        <div className="UserInfo-section">
          <div className="row">
            <div className="col-lg-12">
              <div className="UserInfo-title">
                Profissionais preferenciais de {this.state.user.name}
              </div>
              <div className="UserInfo-removeablesWrapper">
                {this.renderPreferencePartners()}
              </div>
            </div>
          </div>
        </div>

        <div className="UserInfo-section">
          <div className="row">
            <div className="col-lg-12 col-centered text-center">
              <h3>Vouchers para {this.state.user.name}</h3>
              <hr />
            </div>
          </div>
          {this.renderVouchers()}
        </div>

        <div className="UserInfo-section">
          <div className="row">
            <div className="col-lg-12">
              <div className="UserInfo-title">
                <div className="row">
                  <div className="col-lg-12 col-centered text-center">
                    <h3>Singu créditos</h3>
                    <hr />
                  </div>
                </div>
                <div className="UserInfo-container">
                  <p>
                    <small>
                      Total Disponível: R${" "}
                      {this.state.user.singuCredits || "0,00"}
                    </small>
                  </p>
                  <Button
                    className="addCredits"
                    variant="primary"
                    onClick={() => this.onAddCreditsClick()}
                  >
                    Adicionar ou remover singu creditos
                  </Button>
                </div>

                {/** copiar esse modal  */}
                <Modal
                  show={this.state.modalOpened}
                  onHide={() => this.closeModal()}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Remoção de Singu Créditos</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div className="form-group">
                      <label>Créditos* (SOMENTE NEGATIVO)</label>
                      <input
                        type="number"
                        className={`Form-input ${this.state.singuCreditsError ? "is-invalid" : ""
                          }`}
                        max="1000"
                        value={this.state.singuCreditsValue}
                        onChange={(e) =>
                          this.setState(
                            {
                              singuCreditsValue:
                                e.target.value > 1000 ? 1000 : e.target.value,
                            },
                            () => {
                              this.validateCredits();
                            }
                          )
                        }
                      />
                      <div className="invalid-feedback">
                        {this.state.singuCreditsError}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Descrição*</label>
                      <textarea
                        className={`Form-input ${this.state.singuDescriptionError ? "is-invalid" : ""
                          }`}
                        value={this.state.description}
                        onChange={(e) =>
                          this.setState(
                            {
                              description: e.target.value
                                .trimStart()
                                .replace(/\s\s+/g, " "),
                            },
                            () => {
                              this.validateDescription();
                            }
                          )
                        }
                      ></textarea>
                      <div className="invalid-feedback">
                        {this.state.singuDescriptionError}
                      </div>
                    </div>

                    <div className="form-group">
                      <label>Motivo *</label>
                      <select
                        className={`Form-input ${this.state.reasonError ? "is-invalid" : ""
                          }`}
                        onChange={(e) =>
                          this.setState({ reason: e.target.value }, () => {
                            this.validateReason();
                          })
                        }
                        value={this.state.reason}
                      >
                        <option></option>
                        {insertedCreditsReasons.map((reason) => (
                          <option key={reason} value={reason}>
                            {reason}
                          </option>
                        ))}
                      </select>
                    </div>
                  </Modal.Body>

                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => this.closeModal()}
                    >
                      Cancelar
                    </Button>
                    <Button
                      disabled={this.state.savingCredits}
                      variant="primary"
                      onClick={this.saveSinguCredits}
                    >
                      Adicionar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>

              <div className="UserInfo-removeablesWrapper">
                {this.displaySinguCredits()}
              </div>
            </div>
          </div>
        </div>

        <div className="UserInfo-section">
          <div className="UserInfo-title">
            <div className="row">
              <div className="col-xs-12 col-centered">
                <Comments
                  type={"user"}
                  id={(user && user.id) || ""}
                  enableNewComment={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="UserInfo-section">
          <div className="row">
            <div className="col-lg-12 col-centered text-center">
              <h3>Agendamentos de {this.state.user.name}</h3>
              <hr />
            </div>
          </div>
          {this.renderSchedules()}
        </div>
      </div>
    );
  }
}

UserInfo.propTypes = {
  schedules: PropTypes.array,
  vouchers: PropTypes.array,
  areas: PropTypes.array,
  user: PropTypes.object,
  removeAddress: PropTypes.func,
  addAddress: PropTypes.func,
  removeCard: PropTypes.func,
  updateUser: PropTypes.func,
  fetchSchedules: PropTypes.func,
  fetchAreas: PropTypes.func,
  updateScheduleAddress: PropTypes.func,
  updateSchedulePartner: PropTypes.func,
  removeSchedulePartner: PropTypes.func,
  updateScheduleDate: PropTypes.func,
  updateScheduleService: PropTypes.func,
  updateScheduleVoucher: PropTypes.func,
  cancelSchedule: PropTypes.func,
  rechargeSchedule: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    user: state.userInfo.user,
    schedules: state.schedules.all,
    vouchers: state.vouchers.all,
    areas: state.areas.all,
    numberOfPages: state.schedules.numberOfPages,
  };
}

const mapActionsToProps = {
  fetchUser,
  fetchVouchersUsingUserId,
  setSchedules,
  fetchAreas,
  removeAddress,
  addAddress,
  removeCard,
  updateUser,
  updateSinguCredits,
  resetUserPassword,
  updateScheduleAddress,
  updateSchedulePartner,
  removeSchedulePartner,
  updateScheduleDate,
  updateScheduleService,
  updateScheduleVoucher,
  cancelSchedule,
  rechargeSchedule,
  fetch_userSchedules,
  fetchUserCreditsHistory,
};

export default connect(mapStateToProps, mapActionsToProps)(UserInfo);
