import axios from 'axios';
import config from '../../../config.json';
const env = process.env.REACT_APP_ENVIRONMENT || 'stage';
const BACKEND_URL = config.api[env];

import { getToken } from '../../models/auth';

export function payBonusToArtist(professionalId, value, reason, description, scheduleId, scheduleDate) {
  return axios.post(`${BACKEND_URL}/transfer/order`,
    { professionalId, value, reason, description, scheduleId, scheduleDate },
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );
}

export function editBonusToArtist(transfer_id, professionalId, value, reason, description, idschedule, scheduleDate) {
  return axios.put(`${BACKEND_URL}/transfer/order/${transfer_id} `,
    { professionalId, value, reason, description, idschedule, scheduleDate },
    {
      headers: { Authorization: `Bearer ${getToken()}` }
    }
  );
}